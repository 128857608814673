import moment from 'moment'
import { friendlyPriority, friendlyStates, friendlyStatus, caseStatesTranslate, itemsSidebar, CasesStates } from '../constants'
import { concatDateAndHourString, diffMomentDates, getDayNow, transformDateToMoment } from './date'

const nF = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0
})

export const mapScgQueryResponse = (arrayInfo, fieldsInclude) => {
  const newObject = {}
  for (const info of arrayInfo) {
    for (const key in info) {
      if (fieldsInclude.includes(key)) newObject[key] = info[key]
    }
  }
  newObject.fullDocument = `${newObject.btiidCodigo} ${newObject.clieCodigo}`

  if (newObject.fechaCorteFiscal) {
    newObject.fechaCorteFiscal = transformDateToMoment(
      new Date(newObject.fechaCorteFiscal)
    ).format('YYYYMMDD')
  }
  if (newObject.fechaReserva) {
    newObject.fechaReserva = transformDateToMoment(
      new Date(newObject.fechaReserva)
    ).format('DD/MM/YYYY')
  }
  if (newObject.valorGarantiaOriginal) {
    newObject.valorGarantiaOriginal = nF
      .format(newObject.valorGarantiaOriginal)
      .replace(/,/g, '.')
  }
  return newObject
}

export const mapReserveRequest = (info) => {
  return {
    guaranteeId: info.garaCodigo,
    period: info.termCommission,
    taxCourtDate: info.fechaCorteFiscal,
    term: info.termObligation,
    unitTerm: info.unitTerm.replace('d', 'Días').replace('m', 'Meses'),
    user: 'GEDESEM1'
  }
}

export const mapCountStatusToDataTable = (process, arrayCount, id) => {
  const newObject = {}
  newObject.id = id
  newObject.Proceso = process
  for (const _status in friendlyStates) {
    let value = '0'
    const statusFound = arrayCount.find((item) => item.key === _status)
    if (statusFound) value = statusFound.doc_count.toString()
    newObject[friendlyStates[_status]] = value
  }
  return newObject
}

export const mapTotalByStatusToDataTable = (arrayCount) => {
  const newObject = {
    id: 'Total',
    Proceso: 'Total',
    [friendlyStates.APPROVED]: 0,
    [friendlyStates.REJECTED]: 0,
    [friendlyStates.PAUSED]: 0,
    [friendlyStates.OPEN]: 0,
    [friendlyStates.PENDING]: 0
  }
  let totalCount = 0
  arrayCount.forEach((_process) => {
    newObject[friendlyStates.APPROVED] += +_process[friendlyStates.APPROVED]
    newObject[friendlyStates.REJECTED] += +_process[friendlyStates.REJECTED]
    newObject[friendlyStates.PAUSED] += +_process[friendlyStates.PAUSED]
    newObject[friendlyStates.OPEN] += +_process[friendlyStates.OPEN]
    newObject[friendlyStates.PENDING] += +_process[friendlyStates.PENDING]
  })
  for (const value of Object.values(newObject)) {
    if (!isNaN(value)) totalCount += value
  }
  newObject[friendlyStates.APPROVED] =
    newObject[friendlyStates.APPROVED].toString()
  newObject[friendlyStates.REJECTED] =
    newObject[friendlyStates.REJECTED].toString()
  newObject[friendlyStates.PAUSED] = newObject[friendlyStates.PAUSED].toString()
  newObject[friendlyStates.OPEN] = newObject[friendlyStates.OPEN].toString()
  newObject[friendlyStates.PENDING] =
    newObject[friendlyStates.PENDING].toString()
  return [totalCount, newObject]
}

export const mapCasesToDataTable = (data) => {
  const itemSideBar = getItemSidebarFromProcess(data[0].process).item
  const isOrderDisbursement = itemSideBar?.slug === 'order-disbursement'
  const requireDocs = isOrderDisbursement && data[0].reqStatus === CasesStates.REJECTED
  return data.map((el, index) => {
    const newObject = {
      id: index,
      original: JSON.stringify(el),
      Proceso: el.process,
      'Fecha recibido': {
        primary: moment(el.dateAssignment).format('DD-MM-YYYY'),
        secondary: el.hourAssignment.toLowerCase()
      }
    }
    if (!requireDocs) newObject.Estado = friendlyStatus[el.reqStatus]
    newObject.Gestor = el.reqUser
    newObject['Fecha gestionado'] = {
      primary: el.reqStatus === 'PENDING' ? '' : moment(el.updatedAt).format('DD-MM-YYYY'),
      secondary: el.reqStatus === 'PENDING' ? '' : moment(el.updatedAt).format('HH:mm:ss a')
    }
    newObject.Prioridad = friendlyPriority[el.priority]
    newObject.Boton = mapButtonLeaderTable(el.reqStatus, requireDocs)
    return newObject
  })
  /* return data.map((el, index) => ({
    id: index,
    original: JSON.stringify(el),
    Proceso: el.process,
    'Fecha recibido': {
      primary: moment(el.dateAssignment).format('DD-MM-YYYY'),
      secondary: el.hourAssignment.toLowerCase()
    },
    // Estado: friendlyStatus[el.reqStatus],
    Gestor: el.reqUser,
    'Fecha gestionado': {
      primary: el.reqStatus === 'PENDING' ? '' : moment(el.updatedAt).format('DD-MM-YYYY'),
      secondary: el.reqStatus === 'PENDING' ? '' : moment(el.updatedAt).format('HH:mm:ss a')
    },
    Prioridad: friendlyPriority[el.priority],
    Boton: (el.reqStatus === 'REJECTED' || el.reqStatus === 'APPROVED') ? '' : el.reqStatus === 'PENDING' ? 'Priorizar ›' : 'Reasignar ›'
  })) */
}
const mapButtonLeaderTable = (_status, requireDocs) => {
  const generalConidtions = {
    [CasesStates.REJECTED]: '',
    [CasesStates.APPROVED]: '',
    [CasesStates.PENDING]: 'Priorizar ›'
  }
  if (requireDocs) return 'Ver ›'
  return generalConidtions[_status] ?? 'Reasignar ›'
}
export const discoverUser = (username) => {
  const usernameSplit = username.split('@')[0]
  return usernameSplit.replace(`${process.env.REACT_APP_ID_PROVIDER}_`, '')
}
export const mapUpdateCase = (_status, uuidP, dataCase, productsNumber) => {
  const endDate = getDayNow().format('YYYY-MM-DDTHH:mm:ss')
  const reqStatus = caseStatesTranslate[_status] ?? _status
  const reasonReturn = _status === 'Devolver' ? dataCase.textAreaValue : _status === 'Pausar' ? dataCase.dropDownValue : ''
  const idP = dataCase.idProductivity ?? uuidP
  return {
    requestNumber: dataCase.requestNumber,
    file: dataCase.file,
    hourAssignment: dataCase.hourAssignment,
    id: dataCase.id,
    dateAssignment: dataCase.dateAssignment,
    process: dataCase.process,
    priority: dataCase.priority,
    createdAt: dataCase.createdAt,
    reqStatus,
    fileOrder: dataCase.fileOrder,
    reasonReturn,
    endDate,
    idProductivity: idP,
    requestedProducts: productsNumber
  }
}

export const mapProductivityAutomatic = (_status, uuidP, productsNumber, activeCase, dataStaff, dataProcess) => {
  let endDate = getDayNow()
  const dateRef = concatDateAndHourString(activeCase.dateAssignment, activeCase.hourAssignment)
  const cycleTime = diffMomentDates(endDate, dateRef, 'miliseconds')
  endDate = endDate.format('YYYY-MM-DDTHH:mm:ss')
  const processInfo = dataProcess.find((data) => data.process === activeCase.process)
  const idFirstPaused = activeCase.idProductivity ?? uuidP
  return {
    id: uuidP,
    idFirstPaused,
    management: activeCase.management,
    area: activeCase.area,
    new: activeCase.process,
    user: activeCase.reqUser,
    createAt: activeCase.createdAt,
    documentNumber: dataStaff.documentNumber,
    fullName: activeCase.reqUser,
    rrhhManagement: dataStaff.management,
    ammount: Number(activeCase.amountApproved),
    comments: activeCase.requestNumber,
    beginDate: activeCase.beginDate.split('T')[1],
    date: getDayNow().format('YYYY/MM/DD'),
    endDate: endDate.split('T')[1],
    isMasiveProccess: 0,
    isAutomatic: true,
    hasAmount: true,
    approvedRegisters: '',
    aprovedCount: _status === 'Aprobar' ? productsNumber : '0',
    daylyGoal: processInfo.daylyGoal,
    othersCount: '1',
    returnedCount: _status === 'Devolver' ? productsNumber : '0',
    returnedRegisters: '',
    cycleTime,
    vertical: 'Dirección del Activo'
  }
}

export const getItemSidebarFromProcess = (process) => {
  let optionSidebar = {}
  Object.entries(itemsSidebar).forEach(item => {
    if (typeof(item[1]) !== 'object') return
    const indexItem = item[1].findIndex((option) => option.process === process)
    if (indexItem !== -1) optionSidebar = { item: item[1][indexItem], index: indexItem }
  })
  return optionSidebar
}
